// Defined by generated-variables.scss
const breakpoints = {
  s: 421,
  m: 701,
  l: 1181,
  xl: 1481,
};

const isMobile = () => {
  return window.innerWidth <= breakpoints.l - 1;
};

export default isMobile;

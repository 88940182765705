// 3rd
import Vue from 'vue';
import TrioAssets from 'trio-assets-js';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

// styling
import './scss/app.scss';

// components
import Accordion from './components/Accordion';
import Blog from './components/Blog';
import HubspotForm from './components/HubspotForm';
import LoadingOverlay from './components/LoadingOverlay';
import MainMenu from './components/MainMenu';
import Parallax from './components/Parallax';
import Projects from './components/Projects';
import Slider from './components/Slider';
import VideoYoutube from './components/VideoYoutube';

// scripts
// utils
import getCurrentPosition from './js/utils/getElementPositionOnScroll';
import scrollIt from './js/utils/scrollHelper';
// elements
import anchorLink from './js/elements/anchorLink';
import frontPage from './js/elements/frontpage';
import initCollage from './js/elements/collageInteractive';
import initTabs from './js/elements/tabs';
import revealLogoWall from './js/elements/logoWall';
import scrollFader from './js/elements/horizontalSwipeOverlay';

// install vee-validate rules
extend('required', required);
extend('email', email);

Vue.config.productionTip = false;

// mount components
Vue.component('accordion', Accordion);
Vue.component('blog', Blog);
Vue.component('hubspot-form', HubspotForm);
Vue.component('loading-overlay', LoadingOverlay);
Vue.component('main-menu', MainMenu);
Vue.component('parallax', Parallax);
Vue.component('projects', Projects);
Vue.component('slider', Slider);
Vue.component('video-youtube', VideoYoutube);

// custom Vue directive – scroll to reveal element
const scrollFadeDirective = {
  inserted(el, binding) {
    const revealClass = binding.value.animation; // the value you enter when adding the directive to an element
    const reveal = () => {
      const elementPosition = getCurrentPosition(el);

      if (window.scrollY > elementPosition.y - window.innerHeight - 100) {
        el.classList.add(revealClass);
      }
    };

    window.addEventListener('scroll', reveal);
  },
};

// register the directive
Vue.directive('scroll-fade', scrollFadeDirective);

// vue instance
window.app = new Vue({
  el: '#app',
  comments: true,
  data() {
    return {
      bus: new Vue(),
      ytPlayers: [], // bc onYouTubeIframeAPIReady is only called once
    };
  },
  methods: {
    scrollToItem(item, offset = false) {
      if (document.querySelector('#frontpage')) {
        this.bus.$emit('contact');
      }
      scrollIt(document.querySelector(item), 1200, 'easeInOutQuad', offset);
    },
    scrollToCoords(y) {
      scrollIt(y, 1200, 'easeInOutQuad');
    },
    initCollageInteractive() {
      const collages = document.querySelectorAll('.collage-interactive');
      collages.forEach((c) => {
        initCollage(c);
      });
    },
    handleScroll() {
      const scrolledHeight = 50;
      const body = document.querySelector('body');
      if (window.scrollY > scrolledHeight) {
        this.$refs.siteLogo.classList.add('site-logo--scroll');
        body.classList.add('body--scrolling');
      } else {
        this.$refs.siteLogo.classList.remove('site-logo--scroll');
        body.classList.remove('body--scrolling');
      }
    },
    removeIntroVideoOnEnd() {
      const introVideo = document.querySelector('#fp-video-intro');
      const loopVideo = document.querySelector('#fp-video-loop');

      if (introVideo && loopVideo) {
        introVideo.addEventListener('ended', () => {
          introVideo.remove();
          loopVideo.classList.remove('front-page-sections__video--hidden');
        });
      }
    },
  },
  mounted() {
    TrioAssets.parallax();

    // scroll categories on mobile (Projects and Blog Overview)
    scrollFader();

    // logo wall section transition delay for logos
    revealLogoWall();

    this.$nextTick(() => {
      // add anchor link button to the content header
      // (only on CT Agency detail)
      anchorLink();

      // init tabs if paragraph is added
      const tabs = document.querySelectorAll('.paragraph-tabs .tabs');
      if (tabs.length) {
        tabs.forEach((tab) => initTabs(tab));
      }

      // init PT Collage interactive
      this.initCollageInteractive();

      // remove front page video after it ends playing
      this.removeIntroVideoOnEnd();
    });

    // init js for the front page (scrolling behavior)
    frontPage();

    // Initialize YT iframe API
    window.onYouTubeIframeAPIReady = () => {
      this.ytPlayers.forEach((func) => func.call());
    };

    // add smooth scrolling to anchor links
    document.querySelectorAll('a[href^="#"]').forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const href = link.getAttribute('href');
        if (href.replace('#', '').length) {
          this.$root.scrollToItem(href, true);
        }
      });
    });

    window.addEventListener('scroll', this.handleScroll);
  },
});

const titleActiveClass = 'tabs__title--active';
const contentActiveClass = 'tabs__content--active';
const contentTransition = 'tabs__content--transition';

let lastActive = 0;

const setActiveFirst = (title, content) => {
  title.classList.add(titleActiveClass);
  content.classList.add(contentActiveClass);

  // set content height
  const height = content.clientHeight;
  content.parentNode.style.setProperty('--height', `${height}px`);
};

const setContentHeight = (content) => {
  const contentMinHeight = content.scrollHeight;
  content.parentNode.style.setProperty('--height', `${contentMinHeight}px`);
};

const setActiveTab = (target, titles, content) => {
  const childIdx = titles.indexOf(target);

  // stop it if clicked on active element
  if (childIdx === lastActive) return;

  const activeId = target.getAttribute('id');
  const parent = target.closest('.tabs');
  const activeContent = parent.querySelector(`[data-tab="${activeId}"]`);
  const oldContent = parent.querySelector(`.${contentActiveClass}`);

  // add transition class to all content
  content.forEach((c) => c.classList.add(contentTransition));
  // get height of the element coming in & set update the CSS variable
  setContentHeight(activeContent);

  // check for previous active tab & add classes:
  // if new active is bigger -->
  //      new active from left & old active to right
  if (childIdx > lastActive) {
    activeContent.classList.add(`${contentTransition}--left`);
    oldContent.classList.add(`${contentTransition}--right`);
  }
  // if new active is smaller -->
  //      new active from right & old active to left
  else if (childIdx < lastActive) {
    activeContent.classList.add(`${contentTransition}--right`);
    oldContent.classList.add(`${contentTransition}--left`);
  }

  // handle active classes
  // remove content active classes from all items
  // add active class to the new active content
  const activeClasses = setTimeout(() => {
    clearTimeout(activeClasses);
    content.forEach((c) => c.classList.remove(contentActiveClass));
    activeContent.classList.add(contentActiveClass);
  }, 410);

  // handle all transition classes
  // remove content transition classes from all items
  const transitionClasses = setTimeout(() => {
    clearTimeout(transitionClasses);
    content.forEach((c) =>
      c.classList.remove(
        contentTransition,
        `${contentTransition}--left`,
        `${contentTransition}--right`,
      ),
    );
  }, 420);

  // handle tab title classes & active idx
  // remove active class from all titles
  titles.forEach((title) => title.classList.remove(titleActiveClass));
  // add active class for the active title
  target.classList.add(titleActiveClass);
  // handle position for the active style
  target.parentNode.style.setProperty('--active-idx', childIdx);

  // update new last active
  lastActive = childIdx;
};

const addResizeListener = (tabs) => {
  window.addEventListener('resize', () => {
    const activeContent = tabs.querySelector('.tabs__content--active');
    setContentHeight(activeContent);
  });
};

const initTabs = (tabs) => {
  const titles = tabs.querySelectorAll('.tabs__title');
  const contents = tabs.querySelectorAll('.tabs__content');

  // set the active tab (1st item) on init
  setActiveFirst(titles[0], contents[0]);

  titles.forEach((title) => {
    title.addEventListener('click', ({ target }) => {
      setActiveTab(target, [...titles], [...contents]);
    });
  });

  // make sure the height of the content area stays updated
  addResizeListener(tabs);
};

export default initTabs;

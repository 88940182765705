<template>
  <div class="view--blog">
    <div class="view--blog__content">
      <div class="view--blog__title-top hide-to-reveal">
        {{ titleTop }}
      </div>
      <h1 class="view--blog__title hide-to-reveal">
        {{ title }}
      </h1>

      <!-- filters -->
      <div class="taxonomy-links__container hide-to-reveal delay--fast">
        <div class="taxonomy-links">
          <div
            class="taxonomy-link"
            :class="{
              'taxonomy-link--active': activeCategory === filter.machine_name,
            }"
            v-for="filter in filters.categories"
            :key="filter.id"
            @click="handleCategoryClick(filter.machine_name)">
            {{ filter.name }}
            <span>{{ countEntries(filter.id) }}</span>
          </div>
        </div>
      </div>

      <!-- content -->
      <div v-if="filteredPosts.length"
        class="view--blog__posts">
        <div class="view--blog__rows row">
          <div
            class="view--blog__row column"
            v-for="(post, idx) in currentPageItems"
            :key="post.id">
            <blog-post :first="idx === 0" :post="post"></blog-post>
          </div>
        </div>
      </div>
    </div>

    <!-- show more button -->
    <div
      v-if="
        filteredPosts.length > currentPageItems.length && totalPageCount > 1
      "
      class="pager__item">
      <a
        @click.prevent="setPage(currentPage + 1)"
        class="button button--secondary">
        <span class="button__text">{{ t.blog.loadMore }}</span>
      </a>
    </div>
  </div>
</template>

<script>
// 3rd
import 'url-search-params-polyfill';
// components
import BlogPost from './BlogPost';
// utils
import translationStrings from '../js/utils/translationStrings';

export default {
  name: 'blog',
  components: {
    BlogPost,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleTop: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    posts: {
      type: Array,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      activeCategory: 'All',
      urlParams: new URLSearchParams(window.location.search),
      currentPage: 0,
      totalPageCount: 0,
      currentPageItems: [],
      itemsPerPage: 7,
      t: translationStrings,
    };
  },
  computed: {
    filteredPosts() {
      // @todo this shouldn't be dependent on string
      if (this.activeCategory === 'alle') {
        return this.posts;
      }

      return this.posts.filter((post) => {
        return post.categories.some(
          (cat) => cat.machine_name === this.activeCategory,
        );
      });
    },
  },
  watch: {
    filteredPosts(val, oldVal) {
      // Update URL params with active filter selection
      // but only after initial value has been computed
      if (oldVal.length) {
        this.setUrlParams();
      }
    },
    activeCategory() {
      this.currentPage = 0;
      this.resetPager();

      // trigger scroll so the items show up
      this.triggerScroll();
    },
  },
  mounted() {
    // check URL for params
    if (this.urlParams.toString()) {
      this.parseUrlParams();
    } else {
      this.activeCategory = 'alle';
    }

    // reset pager
    this.resetPager();

    // trigger scroll so the items show up
    this.triggerScroll();
  },
  methods: {
    countEntries(id) {
      if (id === 0) {
        return this.posts.length;
      }

      return Object.values(this.posts).reduce((acc, curr) => {
        if (curr.categories.some((cat) => cat.id === id)) {
          const increase = (acc += 1);
          return increase;
        }

        return acc;
      }, 0);
    },
    handleCategoryClick(machine_name) {
      this.activeCategory = machine_name;
    },
    setUrlParams() {
      this.urlParams = new URLSearchParams({ category: this.activeCategory });
      // Update URL with new URL params
      if (this.urlParams.toString()) {
        history.replaceState(null, '', `?${this.urlParams.toString()}`);
      } else {
        history.replaceState(null, '', window.location.pathname);
      }
    },
    parseUrlParams() {
      this.urlParams.forEach((param) => {
        this.activeCategory = param;
      });
    },
    setPage(page) {
      this.currentPage = page;
      this.currentPageItems = this.filteredPosts.slice(
        0,
        this.currentPage * Number(this.itemsPerPage - 1) +
          Number(this.itemsPerPage),
      );

      // trigger scroll so the items show up
      this.triggerScroll();
    },
    resetPager() {
      this.totalPageCount = Math.ceil(
        this.filteredPosts.length / Number(this.itemsPerPage),
      );
      this.currentPageItems = this.filteredPosts.slice(
        0,
        Number(this.itemsPerPage),
      );
    },
    triggerScroll() {
      // not sure why this is needed. but it is.
      window.scrollTo(window.pageXOffset, window.pageYOffset - 1);
      window.scrollTo(window.pageXOffset, window.pageYOffset + 1);
    },
  },
};
</script>

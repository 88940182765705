import Swiper, { Autoplay, Controller, EffectFade, Thumbs } from 'swiper';
// utils
import isMobile from '../utils/isMobile';

const onMobile = isMobile();

const options = {
  centeredSlides: true,
  centeredSlidesBounds: true,
  initialSlide: -1,
  observeParents: true,
  observer: true,
  slideToClickedSlide: true,
  watchSlidesProgress: true,
};

const setHeight = (slides, collage) => {
  // sort slides by scrollHeight (bc we need the max height of active slide)
  slides.sort((a, b) => b.scrollHeight - a.scrollHeight);

  // get the height of the active slide
  const tallestSlideHeight = slides[0].scrollHeight;

  // remove the tallest slide from the array
  const restSlides = slides.slice(1);

  // hide the non-active texts
  collage.classList.add('collage-interactive--prepare');

  // get the height of the non-active slides
  const restSlidesHeight = restSlides.reduce(
    (prev, curr) => prev + curr.offsetHeight,
    0,
  );

  // sum up the height
  const combinedHeight = `${tallestSlideHeight + restSlidesHeight}px`;

  // update the CSS variable on paragraph
  collage.style.setProperty('--slider-height', combinedHeight);
};

const initImages = (images, textsSwiper) => {
  let optionsCustom = {};
  if (!onMobile) {
    optionsCustom = {
      thumbs: {
        multipleActiveThumbs: false,
        swiper: textsSwiper,
      },
    };
  }

  return new Swiper(images, {
    ...options,
    ...optionsCustom,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    breakpoints: {
      421: {
        spaceBetween: 40,
      },
      701: {
        spaceBetween: 50,
      },
      1181: {
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: { crossFade: true },
        spaceBetween: 0,
        virtualTranslate: true,
      },
    },
    controller: {
      by: 'container',
      control: textsSwiper,
    },
    modules: [Autoplay, Controller, EffectFade, Thumbs],
    on: {
      autoplayStop() {
        if (!textsSwiper) return;
        textsSwiper.el.classList.add('swiper-thumbs--stop');
      },
    },
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 400,
  });
};

const initTexts = (texts) => {
  const slidesLength = texts.querySelectorAll('.swiper-slide').length;

  return new Swiper(texts, {
    ...options,
    breakpoints: {
      421: {
        spaceBetween: 40,
      },
      701: {
        spaceBetween: 50,
      },
      1181: {
        direction: 'vertical',
        simulateTouch: false,
        slidesPerGroup: slidesLength,
        slidesPerView: null,
        spaceBetween: 0,
      },
    },
    direction: 'horizontal',
    modules: [Controller],
    slidesPerView: 1,
    spaceBetween: 30,
  });
};

const initCollage = (collage) => {
  const images = collage.querySelector('.collage-interactive__images');
  const texts = collage.querySelector('.collage-interactive__texts');
  const slides = [...texts.querySelectorAll('.swiper-slide')];

  // texts first bc they are 'thumbnails'
  const textsSwiper = initTexts(texts);
  const imagesSwiper = initImages(images, textsSwiper);

  if (onMobile) {
    textsSwiper.controller.control = imagesSwiper;

    // stop animation and autoplay on touch
    [textsSwiper, imagesSwiper].forEach((s) => {
      s.on('touchStart', () => {
        s.el.classList.add('swiper-thumbs--stop');
        imagesSwiper.autoplay.stop();
      });
    });
  }

  setTimeout(() => {
    setHeight(slides, collage);
  }, 79);

  window.addEventListener('resize', () => {
    setHeight(slides, collage);
  });
};

export default initCollage;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view--projects"},[_c('div',{staticClass:"view--projects__content"},[_c('div',{staticClass:"view--projects-title-top hide-to-reveal"},[_vm._v(" "+_vm._s(_vm.titleTop)+" ")]),_c('h1',{staticClass:"view--projects__title hide-to-reveal"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"taxonomy-links__container hide-to-reveal delay--medium"},[_c('div',{staticClass:"taxonomy-links"},_vm._l((_vm.filters.categories),function(filter){return _c('div',{key:filter.id,staticClass:"taxonomy-link",class:{
            'taxonomy-link--active': _vm.activeCategory === filter.machine_name,
          },on:{"click":function($event){return _vm.handleCategoryClick(filter.machine_name)}}},[_vm._v(" "+_vm._s(filter.name)+" "),_c('span',[_vm._v(_vm._s(_vm.countEntries(filter.id)))])])}),0)]),_c('div',{staticClass:"view--projects__projects"},[_c('div',{directives:[{name:"scroll-fade",rawName:"v-scroll-fade",value:({ animation: 'reveal' }),expression:"{ animation: 'reveal' }"}],staticClass:"view--projects__rows row"},_vm._l((_vm.filteredProjects),function(project){return _c('div',{key:project.id,staticClass:"view--projects__row column"},[_c('a',{directives:[{name:"scroll-fade",rawName:"v-scroll-fade",value:({ animation: 'reveal' }),expression:"{ animation: 'reveal' }"}],staticClass:"project-teaser",attrs:{"href":project.url}},[_c('div',{staticClass:"project-teaser__image"},[_c('div',{staticClass:"project-teaser__background-image"},[_c('responsive-image',{attrs:{"images":project.images}})],1)]),_c('div',{staticClass:"project-teaser__description"},[_c('div',{staticClass:"project-teaser__logo"},[_c('responsive-image',{attrs:{"images":project.logo}})],1),_c('h3',{staticClass:"project-teaser__title"},[_vm._v(" "+_vm._s(project.title)+" ")]),_c('div',{class:[
                  'project-teaser__categories',
                  'label__categories',
                  'label__categories--dark',
                ]},_vm._l((project.categories),function(category,idx){return _c('div',{key:idx,staticClass:"label__category"},[_vm._v(" "+_vm._s(category.name)+" ")])}),0)])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
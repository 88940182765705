const scrollFader = () => {
  const categories = document.querySelector('.taxonomy-links');
  const container = document.querySelector('.taxonomy-links__container');
  const handleScroll = () => {
    if (
      categories.offsetWidth + categories.scrollLeft >=
      categories.scrollWidth
    ) {
      container.classList.add('scroll--end');
    } else if (categories.scrollLeft === 0) {
      container.classList.add('scroll--start');
    } else {
      container.classList.remove('scroll--end', 'scroll--start');
      container.classList.add('scroll');
    }
  };

  if (categories) {
    categories.addEventListener('scroll', handleScroll);
  }
};

export default scrollFader;

<template>
  <transition name="fade">
    <div class="loading-overlay" v-show="loading">
      <!-- IE alert -->
      <div v-if="isIE" class="isIE">
        <strong>{{ `${t.loading.title} 😱` }}</strong>
        <p>{{ t.loading.description }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
// utils
import translationStrings from '../js/utils/translationStrings';

export default {
  data() {
    return {
      loading: true,
      isIE: false,
      t: translationStrings,
    };
  },
  created() {
    window.addEventListener('load', this.removeSelf);
    this.isIE = /Trident\/|MSIE/.test(window.navigator.userAgent);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.removeSelf);
  },
  methods: {
    removeSelf() {
      if (!this.isIE) {
        this.loading = false;
        this.$nextTick(() => {
          this.shrinkParallax();
          this.animateHiddenElements();
        });
      }
    },
    // Animate text elements in parallax on page load.
    animateHiddenElements() {
      const elements = [...document.querySelectorAll('.hide-to-reveal')];
      if (elements.length) {
        elements.forEach((element) => {
          element.classList.add('reveal');
          element.classList.remove('hide-to-reveal');
        });
      }

      // if on a Basic Page (e.g. Privacy Policy or Imprint)
      const basicPage = document.querySelector('.node--type-page');
      if (basicPage) {
        const firstParagraph = document.querySelector('.paragraph');
        firstParagraph.classList.add('reveal');
      }

      // if on Projects view
      const projectTeasers = [...document.querySelectorAll('.project-teaser')];
      if (projectTeasers) {
        let delay = 0.6;
        const firstTwoTeasers = projectTeasers.splice(0, 2);
        firstTwoTeasers.forEach((teaser) => {
          teaser.style.transitionDelay = `${delay}s`;
          teaser.classList.add('reveal');
          delay += 0.2;
        });
      }

      // if service list
      const serviceListCols = [
        ...document.querySelectorAll('.service-list__content .column'),
      ];
      if (serviceListCols.length) {
        let delay = 0.6;
        const firstTwoCols = serviceListCols.splice(0, 2);
        firstTwoCols.forEach((col) => {
          col.style.transitionDelay = `${delay}s`;
          col.classList.add('reveal');
          delay += 0.2;
        });
      }
    },
    // Decrease parallax height from 100vh to 80vh on page load
    // (solutions detail, agency detail, services category and detail pages).
    shrinkParallax() {
      const expandedParallax = document.querySelector('.parallax--expanded');
      if (expandedParallax) {
        expandedParallax.classList.remove('parallax--expanded');
      }
    },
  },
};
</script>

<template>
  <a
    class="blog-teaser"
    :class="{ 'blog-teaser--first': first }"
    v-scroll-fade="{ animation: 'reveal' }"
    :href="post.url">
    <div class="blog-teaser__image">
      <responsive-image :images="post.images" :lazy="!first" />
    </div>
    <div class="blog-teaser__description">
      <div class="blog-teaser__meta">
        <p class="blog__publish-date">{{ post.published }}</p>
        <p class="blog__reading-time" v-if="post.reading_time">
          {{ `${post.reading_time} ${t.blog.readingTime}` }}
        </p>
      </div>
      <h2 class="blog-teaser__title" v-if="first">{{ post.title }}</h2>
      <h3 class="blog-teaser__title" v-else>{{ post.title }}</h3>
      <p class="blog-teaser__teaser-text">{{ post.teaser_text }}</p>
      <div class="blog__author blog__author--teaser">
        <!-- Reuse existing author view mode markup for CT Contact person. -->
        <div class="author">
          <span class="author__by">{{ t.blog.by }}</span>
          <span class="author__image author__image--small">
            <responsive-image :images="post.author_images" />
          </span>
          <span class="author__name">
            {{ post.author_name }}
          </span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
// components
import ResponsiveImage from './ResponsiveImage';
// utils
import translationStrings from '../js/utils/translationStrings';

export default {
  name: 'blog-post',
  components: {
    ResponsiveImage,
  },
  props: {
    first: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      t: translationStrings,
    };
  },
};
</script>

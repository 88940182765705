// Get an element's current position.
const getCurrentPosition = (el) => {
  const rect = el.getBoundingClientRect();

  return {
    y: rect.top + window.pageYOffset,
    x: rect.left + window.pageXOffset,
  };
};

export default getCurrentPosition;

// Calculate 100vh height for an element minus the height of the header.
const calculateHeight = (elem) => {
  const header = document.querySelector('header.header');

  if (elem) {
    elem.style.height = `${window.innerHeight - header.clientHeight}px`;
  }
};

export default calculateHeight;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-nav-menu",style:({ '--menu-wrapper-width': '84vw' })},[(!_vm.onMobile)?_c('nav',{staticClass:"main-menu"},[_c('ul',{staticClass:"main-menu__items"},_vm._l((_vm.mainMenuData),function(item){return _c('li',{key:item.url,staticClass:"main-menu__item"},[_c('a',{ref:"navigation",refInFor:true,staticClass:"main-menu__link",class:{
            'main-menu__link--active': item.is_collapsed,
            'main-menu__link--current': item.in_active_trail,
            collapsed: item.is_expanded,
          },attrs:{"href":item.url,"target":item.target},on:{"click":function($event){return _vm.openDropdown($event, _vm.mainMenuData, item)}}},[_c('span',{staticClass:"main-menu__link-title"},[_vm._v(_vm._s(item.title))]),(item.show_number)?_c('span',{staticClass:"main-menu__link-number"},[_vm._v(" "+_vm._s(_vm.openPositionsCount)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(item.is_expanded)?_c('ul',{staticClass:"main-sub-menu__items"},[_c('li',[_c('a',{staticClass:"main-menu__link main-menu__link--overview",attrs:{"href":item.url,"target":item.target}},[_vm._v(" "+_vm._s(`${item.title} ${_vm.t.mainMenu.overview}`)+" ")])]),_vm._l((item.below),function(sub_item){return _c('li',{key:sub_item.url,staticClass:"main-sub-menu__item"},[_c('a',{staticClass:"main-sub-menu__link",class:{
                  'main-menu__link--current': sub_item.in_active_trail,
                },attrs:{"href":sub_item.url,"target":sub_item.target}},[_vm._v(" "+_vm._s(sub_item.title)+" ")])])})],2):_vm._e()])],1)}),0)]):_vm._e(),(_vm.onMobile)?[_c('div',{staticClass:"main-menu__toggle",class:{ 'main-menu__toggle-button--open': _vm.showMobileMenu }},[_c('button',{staticClass:"main-menu__toggle-button",on:{"click":_vm.toggleMobileMenu}},[_vm._m(0),_c('span',{staticClass:"main-menu__toggle-text"},[_vm._v(" "+_vm._s(_vm.t.mainMenu.menu)+" ")])])]),_c('div',{ref:"mobileNavigation",staticClass:"main-menu__wrapper"},[_c('div',{staticClass:"main-menu--mobile",class:{
          'main-menu--mobile-dropdown-open': _vm.dropdownOpen,
        }},[_c('transition-group',{staticClass:"main-menu__items main-menu__items--mobile",attrs:{"tag":"ul","name":"slide-fade"}},[(_vm.showMobileMenu)?_vm._l((_vm.mainMenuData),function(item,index,idx){return _c('li',{key:`0${index}`,staticClass:"main-menu__item main-menu__item--mobile",style:({ transitionDelay: `${(idx + 1) * 0.1 - 0.1}s` })},[_c('a',{staticClass:"main-menu__link main-menu__link--mobile",class:{
                  'main-menu__link--active': item.is_collapsed,
                  collapsed: item.is_expanded,
                },attrs:{"href":item.url,"target":item.target},on:{"click":function($event){return _vm.openDropdown($event, _vm.mainMenuData, item)}}},[_c('span',{staticClass:"main-menu__link-title"},[_vm._v(_vm._s(item.title))]),(item.show_number)?_c('span',{staticClass:"main-menu__link-number"},[_vm._v(" "+_vm._s(_vm.openPositionsCount)+" ")]):_vm._e()]),(item.is_expanded)?_c('ul',{staticClass:"main-sub-menu__items main-sub-menu__items--mobile"},[_c('li',[_c('a',{staticClass:"main-menu__link main-menu__link--overview",attrs:{"href":item.url,"target":item.target}},[_vm._v(" "+_vm._s(`${item.title} ${_vm.t.mainMenu.overview}`)+" ")])]),_vm._l((item.below),function(sub_item){return _c('li',{key:sub_item.url,staticClass:"main-sub-menu__item"},[_c('a',{staticClass:"main-sub-menu__link",attrs:{"href":sub_item.url,"target":sub_item.target}},[_vm._v(" "+_vm._s(sub_item.title)+" ")])])})],2):_vm._e()])}):_vm._e()],2),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showMobileMenu)?_c('a',{staticClass:"button button--secondary",style:({
              transitionDelay: `${(_vm.countMenuItems + 1) * 0.1 - 0.1}s`,
            }),attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollToContact.apply(null, arguments)}}},[_c('span',{staticClass:"button__text"},[_vm._v(" "+_vm._s(_vm.t.mainMenu.toContact)+" ")])]):_vm._e()])],1)])]:_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.showMobileMenu)?_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.close()}}}):_vm._e()])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"main-menu__toggle-icons"},[_c('i',{staticClass:"fa-solid fa-bars"}),_c('i',{staticClass:"fa-solid fa-xmark"})])
}]

export { render, staticRenderFns }
const translationStrings = {
  blog: {
    by: Drupal.t('von'),
    loadMore: Drupal.t('Mehr laden'),
    readingTime: Drupal.t('Min. Lesedauer'),
  },
  loading: {
    title: Drupal.t('Computer sagt NEIN!'),
    description: Drupal.t(
      'Unsere Webseite unterstützt den Internet Explorer nicht länger. Bitte verwenden Sie einen modernen Browser.'
    ),
  },
  mainMenu: {
    menu: Drupal.t('Menü'),
    overview: Drupal.t('Übersicht'),
    toContact: Drupal.t('Kontakt'),
  },
};

export default translationStrings;

<template>
  <section
    class="parallax js-parallax"
    :class="{ 'parallax--video': isVideo }"
    data-mode="background"
    data-y="-100, 150"
    :data-auto="direction"
    :style="[isVideo ? '' : { backgroundImage: `url('${background}')` }]">
    <slot></slot>
    <video
      data-mode="element"
      data-unit="px"
      data-relative-viewport="false"
      data-y="-100, 200"
      ref="video"
      v-if="isVideo"
      autoplay
      muted
      loop
      playsInline
      class="js-parallax parallax__video">
      <source :src="videoDetails.src" :type="videoDetails.type" />
    </video>
  </section>
</template>

<script>
// 3rd
import TrioAssets from 'trio-assets-js';
// utils
import calculateHeight from '../js/utils/calculateHeight';
import isMobile from '../js/utils/isMobile';

export default {
  props: ['background', 'direction', 'videoData', 'bus'],
  data() {
    return {
      isVideo: !!this.videoData,
      videoDetails: this.videoData ? JSON.parse(this.videoData) : {},
    };
  },
  mounted() {
    // If inside of a video parallax (agency page), pause the parallaxing video only while other videos are playing
    if (this.bus) {
      this.bus.$on('playing', () => {
        this.$refs.video.pause();
      });

      this.bus.$on('paused', () => {
        this.$refs.video.play();
      });
    }

    this.calculateParallaxHeights();
    // Activate parallax only on desktop
    if (!isMobile()) {
      TrioAssets.parallax();
    }
  },
  methods: {
    calculateParallaxHeights() {
      // Parallax has 100vh - header.height on project detail pages
      const projectDetailParallax = document.querySelector('.parallax--dark');
      if (projectDetailParallax) {
        calculateHeight(projectDetailParallax);
      }
      // Descrease jitter on mobile devices when viewport changes (those god damn browser toolbars.. )
      const frontpageMain = document.querySelector('.section--main');
      if (frontpageMain) {
        frontpageMain.style.height = `${window.innerHeight}px`;
      }
    },
  },
};
</script>

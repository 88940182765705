<!-- Created this SFC as Vue doesn't allow inline <script> tags. -->
<!-- This will be replaced by the Form factory when we can use Hubspot's API. -->
<template>
  <div :class="`hubspot-form form form--${theme}-theme`">
    <div class="hubspot-form__form-embed" :id="`hf-${formId}`"></div>
  </div>
</template>

<script>
export default {
  name: 'HubspotForm',
  props: {
    formId: {
      type: String,
      required: true,
    },
    theme: {
      type: String, // e.g. either 'light' or 'dark'
      default: 'light',
    },
  },
  mounted() {
    // Fake jQuery for stupid HubSpot JS.
    // (We do have jQuery in Drupal but only when the user is logged in).
    // todo: get rid of this as soon as HubSpot API is available.
    window.jQuery =
      window.jQuery ||
      function (selector) {
        if (typeof selector === 'string') {
          return document.querySelector(selector);
        }
        return selector;
      };
    // Init Hubspot form.
    const hubspotConfig = {
      region: 'eu1',
      portalId: '25488523',
      formId: this.formId,
      target: `#hf-${this.formId}`,
      // Append <span> els below inputs (required by Patrick's layout).
      onFormReady: () => {
        const inputs = document.querySelectorAll(
          '.hs-fieldtype-text .input, .hs-fieldtype-textarea .input',
        );
        inputs.forEach((item) => this.generateBorder(item));
      },
    };
    // Delay create() call because lame UserCentrics delays loading of
    // HubSpot JS and thus causes uncaught reference errors for hbspt var.
    // todo: get rid of this as soon as HubSpot API is available.
    setTimeout(() => {
      hbspt.forms.create(hubspotConfig);
    }, 1000);
  },
  methods: {
    generateBorder(el) {
      const span = document.createElement('span');
      span.className = 'animated-border';
      el.appendChild(span);
    },
  },
};
</script>

<template>
  <div class="view--projects">
    <div class="view--projects__content">
      <div class="view--projects-title-top hide-to-reveal">
        {{ titleTop }}
      </div>
      <h1 class="view--projects__title hide-to-reveal">{{ title }}</h1>

      <!-- filters -->
      <div class="taxonomy-links__container hide-to-reveal delay--medium">
        <div class="taxonomy-links">
          <div
            class="taxonomy-link"
            :class="{
              'taxonomy-link--active': activeCategory === filter.machine_name,
            }"
            v-for="filter in filters.categories"
            :key="filter.id"
            @click="handleCategoryClick(filter.machine_name)">
            {{ filter.name }}
            <span>{{ countEntries(filter.id) }}</span>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="view--projects__projects">
        <div
          class="view--projects__rows row"
          v-scroll-fade="{ animation: 'reveal' }">
          <div
            class="view--projects__row column"
            v-for="project in filteredProjects"
            :key="project.id">
            <a
              :href="project.url"
              class="project-teaser"
              v-scroll-fade="{ animation: 'reveal' }">
              <div class="project-teaser__image">
                <div class="project-teaser__background-image">
                  <responsive-image :images="project.images" />
                </div>
              </div>
              <div class="project-teaser__description">
                <div class="project-teaser__logo">
                  <responsive-image :images="project.logo" />
                </div>
                <h3 class="project-teaser__title">
                  {{ project.title }}
                </h3>
                <div
                  :class="[
                    'project-teaser__categories',
                    'label__categories',
                    'label__categories--dark',
                  ]">
                  <div
                    v-for="(category, idx) in project.categories"
                    class="label__category"
                    :key="idx">
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 3rd
import 'url-search-params-polyfill';
// components
import ResponsiveImage from './ResponsiveImage';

export default {
  name: 'projects',
  components: {
    ResponsiveImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleTop: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      activeCategory: '',
      defaultCategory: '',
      urlParams: new URLSearchParams(window.location.search),
    };
  },
  computed: {
    filteredProjects() {
      return Object.values(this.projects).filter((proj) => {
        // filter projects that have at least one category
        if (this.activeCategory === this.defaultCategory) {
          return proj.categories;
        }
        // filter projects that match category
        if (this.activeCategory && proj.categories) {
          return proj.categories.some(
            (cat) => cat.machine_name === this.activeCategory,
          );
        }

        return false;
      });
    },
  },
  watch: {
    filteredProjects(val, oldVal) {
      // update URL params with active filter selection
      // but only after initial value has been computed
      if (oldVal.length) {
        this.setUrlParams();
      }
    },
  },
  mounted() {
    this.defaultCategory = this.filters.categories[0].machine_name;

    // check URL for params
    this.urlParams.toString()
      ? this.parseUrlParams()
      : (this.activeCategory = this.defaultCategory);
  },
  methods: {
    countEntries(id) {
      return Object.values(this.projects).reduce((acc, curr) => {
        if (
          (curr.categories && curr.categories.some((cat) => cat.id === id)) ||
          (id === 0 && curr.categories)
        ) {
          const increase = (acc += 1);
          return increase;
        }

        return acc;
      }, 0);
    },
    handleCategoryClick(machine_name) {
      this.activeCategory = machine_name;

      // Trigger scroll so the items show up.
      window.scrollTo(window.pageXOffset, window.pageYOffset - 1);
      window.scrollTo(window.pageXOffset, window.pageYOffset + 1);
    },
    setUrlParams() {
      this.urlParams = new URLSearchParams({
        category: this.activeCategory,
      });
      // Update URL with new URL params.
      if (this.urlParams.toString()) {
        history.replaceState(null, '', `?${this.urlParams.toString()}`);
      } else {
        history.replaceState(null, '', window.location.pathname);
      }
    },
    parseUrlParams() {
      this.urlParams.forEach((param) => {
        this.activeCategory = param;
      });
    },
  },
};
</script>

<template>
  <div
    class="slider__swiper delay--slow"
    v-scroll-fade="{ animation: 'reveal' }">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, key) in data"
        :class="`swiper-slide--${type}`"
        :key="key">
        <div class="swiper-slide__background">
          <div
            class="swiper-slider__background-image"
            v-if="type === 'culture'">
            <responsive-image :images="item.title_image" />
          </div>
          <div class="testimonial__overlay" v-if="type === 'culture'"></div>
          <div class="swiper-slide__details">
            <!-- if culture -->
            <div v-if="type === 'culture'" class="testimonial">
              <div class="testimonial__quotes"></div>
              <blockquote v-if="item.quote_text">
                <div class="h2 testimonial__text" data-swiper-parallax="-100">
                  {{ item.quote_text }}
                </div>
                <p class="author__details" data-swiper-parallax="-100">
                  <cite class="author__name">{{ item.person_name }}</cite
                  >, {{ item.person_position }}
                </p>
              </blockquote>
              <!-- needs additional wrapper bc button needs own transitions -->
              <!-- (otherwise parallax styling takes over them) -->
              <div class="testimonial__link" data-swiper-parallax="-100">
                <a :href="item.link" class="button button--primary">
                  <span class="button__text">{{ translate }}</span>
                </a>
              </div>
            </div>
            <!-- if offices -->
            <div v-if="type === 'office'" class="office">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="office__image"
                viewBox="0 0 600 260">
                <defs>
                  <pattern
                    :id="item.office_city_code.toUpperCase()"
                    patternUnits="userSpaceOnUse"
                    width="530"
                    height="300">
                    <image
                      :xlink:href="item.title_image"
                      width="530"
                      height="300"
                      preserveAspectRatio="xMinYMin slice" />
                  </pattern>
                </defs>
                <text
                  y="98%"
                  :fill="`url(#${item.office_city_code.toUpperCase()})`"
                  :class="`office__pattern office__pattern--${item.office_city_code.toUpperCase()}`">
                  {{ item.office_city_code.toUpperCase() }}
                </text>
              </svg>
              <h3 class="office__city">{{ item.title }}</h3>
              <div class="office__address" v-html="item.office_address"></div>
              <!-- additional wrapper to prevent styling overwriting -->
              <div class="office__link">
                <a :href="item.link" class="button button--primary">
                  <span class="button__text">{{ translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slider__pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
// 3rd
import Swiper, { Navigation, Pagination, Parallax } from 'swiper';
// components
import ResponsiveImage from './ResponsiveImage';

export default {
  name: 'slider',
  components: {
    ResponsiveImage,
  },
  props: {
    cultureSlider: {
      type: Array,
      required: false,
    },
    officeSlider: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      data: this.cultureSlider ? this.cultureSlider : this.officeSlider,
      type: this.cultureSlider ? 'culture' : 'office',
      parallax: true,
      index: 0,
      translate: this.cultureSlider
        ? Drupal.t('Read the interview')
        : Drupal.t('Visit Office'),
      swiper: {},
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$el, {
      breakpoints: {
        1024: {
          slidesPerView: this.cultureSlider ? 1 : 2,
        },
      },
      modules: [Navigation, Pagination, Parallax],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        bulletElement: 'span',
        clickable: true,
        dynamicBullets: true,
        el: '.slider__pagination',
        type: 'bullets',
      },
      parallax: true,
      slidesOffsetAfter: 0,
      slidesPerView: 1,
      spaceBetween: 100,
      speed: 600,
    });
  },
};
</script>

import 'swiped-events';
import isMobile from '../utils/isMobile';

let snapables = null;
let $header = null;
let selector = null;
let $fpSections = null;
let currentSectionIndex = null;
let reveals = null;
const elements = [];
let stopScroll = false;

function showPagination() {
  const pagination = document.querySelector('.pagination');
  pagination.classList.remove('pagination--hidden');
}

function hidePagination() {
  const pagination = document.querySelector('.pagination');
  pagination.classList.add('pagination--hidden');
}

function scrollTo(elementDetail) {
  if (stopScroll) return;

  if (elementDetail.length > 0) {
    // just scroll when there is an el available
    if (!isMobile()) {
      window.scrollTo({
        top: elementDetail[0].top - $header.clientHeight,
        behavior: 'smooth',
      });
      return true;
    }
  }
  return false;
}

function setPaginationActive() {
  const pagination = document.querySelector('.pagination');
  const $dots = [...pagination.querySelectorAll('.dot')];
  for (let i = 0; i < $dots.length; i++) {
    $dots[i].classList.remove('dot--active');
  }
  $dots[currentSectionIndex].classList.add('dot--active');

  // change colors for inverted sections
  const shouldInvert = ['dot--active', 'dot--invert'].every((c) =>
    $dots[currentSectionIndex].classList.contains(c),
  );
  if (shouldInvert) {
    pagination.classList.add('pagination--invert');
  } else {
    pagination.classList.remove('pagination--invert');
  }
}

function renderPagination() {
  $fpSections = document.querySelector('.front-page-sections');
  const pagination = document.createElement('div');
  pagination.classList.add('pagination');
  const domNode = document.querySelectorAll(selector);
  for (let i = 0; i < domNode.length; i++) {
    const dot = document.createElement('div');
    dot.classList.add('dot');
    pagination.appendChild(dot);
  }
  $fpSections.parentNode.insertBefore(pagination, $fpSections);

  // make the dots clickable
  const $dots = document.querySelectorAll('.pagination .dot');
  for (let i = 0; i < $dots.length; i++) {
    // eslint-disable-next-line
    $dots[i].addEventListener('click', () => {
      const scrollAction = elements.filter((e) => {
        return i === e.index;
      });
      if (scrollTo(scrollAction)) {
        currentSectionIndex = scrollAction[0].index;
        setPaginationActive();
      }
    });
  }

  // make dots different color on inverted sections
  const sections = [
    ...document.querySelectorAll('.front-page-sections__section'),
  ];
  for (let i = 0; i < $dots.length; i++) {
    const isInvert = sections[i].classList.contains(
      'front-page-sections__section--invert',
    );
    if (isInvert) {
      $dots[i].classList.add('dot--invert');
    }
  }
}

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    // eslint-disable-next-line
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function details(el, index) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: el.clientWidth,
    height: el.clientHeight,
    index,
  };
}

function handleMove(e) {
  // get direction
  let dir = 0;
  // eslint-disable-next-line
  switch (e.type) {
    case 'wheel':
      dir = e.deltaY > 0 ? 1 : 0;
      break;
    case 'swiped-up':
      dir = 1;
      break;
    case 'swiped-down':
      dir = 0;
      break;
    case 'keydown':
      dir = e.which === 40 || e.which === 32 ? 1 : 0;
      break;
  }

  let scrollAction = null;
  if (dir) {
    // scroll down
    scrollAction = elements.filter((evt) => {
      return window.scrollY < evt.top - $header.clientHeight;
    });
  } else {
    // scroll up
    scrollAction = elements.filter((event) => {
      return window.scrollY < event.top + event.height - $header.clientHeight;
    });
  }

  if (scrollTo(scrollAction)) {
    showPagination();
    currentSectionIndex = scrollAction[0].index;
    setPaginationActive();
  } else {
    hidePagination();
  }
}

function handleElementInView(el) {
  el.classList.add('reveal-in-view--show');
}

function isInViewport(elem) {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top - 100 >= 0 &&
    distance.left >= 0 &&
    distance.bottom - 100 <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

function handleScroll() {
  reveals.forEach((element) => {
    if (isInViewport(element)) {
      handleElementInView(element);
    }
  });
}

function initObserver() {
  const footer = document.querySelectorAll('.region-footer');
  // eslint-disable-next-line
  const observer = new IntersectionObserver(
    // eslint-disable-next-line
    (entries, observer) => {
      entries.forEach((entry) => {
        stopScroll = !!entry.isIntersecting;
      });
    },
    { threshold: 0.1 },
  );

  footer.forEach((item) => observer.observe(item));
}

// register all event listeners
function addInputListeners() {
  window.addEventListener('wheel', debounce(handleMove, 300));
  window.addEventListener('swiped-up', debounce(handleMove, 300));
  window.addEventListener('swiped-down', debounce(handleMove, 300));
  document.addEventListener('keydown', debounce(handleMove, 300));
  window.addEventListener('scroll', handleScroll);
}

function init() {
  initObserver();
  $header = document.querySelector('.header');
  selector = '.snapable';
  snapables = document.querySelectorAll(selector);
  reveals = document.querySelectorAll('.reveal-in-view');
  if (snapables.length > 0) {
    for (let i = 0; i < snapables.length; i++) {
      elements.push(details(snapables[i], i));
    }

    renderPagination();
    addInputListeners();
    handleScroll();

    handleMove({ type: 'none' });
  }
}

export default init;

const revealLogoWall = () => {
  const clientParagraph = document.querySelector(
    '.logo-wall-section__field-media--client',
  );
  const logoClusterParagraph = document.querySelector(
    '.logo-wall-section__field-media--logo-cluster',
  );
  const awardParagraph = document.querySelector(
    '.logo-wall-section__field-media--award',
  );
  let delay = 0;

  [clientParagraph, logoClusterParagraph, awardParagraph].forEach((media) => {
    if (!media) return;

    // reset delay
    delay = 0;

    const items = media.querySelectorAll('.logo-wall-section__item');
    // eslint-disable-next-line
    for (const item of items) {
      item.style.transitionDelay = `${delay}s`;
      delay += 0.07;
    }
  });
};

export default revealLogoWall;

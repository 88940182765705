const anchorLink = () => {
  const contentTitleArea = document.querySelector('.content-title-area');
  const openPositionsLink = document.querySelector(
    '.block-open-positions__link',
  );

  if (!!openPositionsLink && !!contentTitleArea) {
    const title = contentTitleArea.querySelector(
      '.content-title-area__content-title',
    );
    title.appendChild(openPositionsLink);

    // adjust transition delay if the button is moved
    document.querySelector(
      '.content-title-area__scroll',
    ).style.transitionDelay = `1s`;
  }
};

export default anchorLink;

<template>
  <div class="video">
    <div
      class="video__poster"
      ref="ytPoster"
      :style="{ backgroundImage: `url('${this.videoData.video_image_url}')` }"
      @click.prevent="play">
      <div
        class="button button--play button--youtube"
        ref="ytPlay"
        @click.prevent="play"></div>
    </div>
    <div :id="playerId" class="video__iframe"></div>
  </div>
</template>

<script>
export default {
  name: 'video-youtube',
  props: {
    videoData: {
      type: Object,
      required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
    value: { // v-model
      type: Array,
      required: true,
    },
    env: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentVideoId: undefined,
      player: undefined,
      playerId: `player-${this.randomHash(6)}`,
      scriptSrc: `${window.location.protocol}//www.youtube.com/iframe_api`,
    };
  },
  methods: {
    play() {
      // Play the video and hide the custom button.
      this.$refs.ytPlay.classList.add('button--hidden');
      this.$refs.ytPoster.classList.add('video__poster--hidden');
      this.player.playVideo();
    },
    onPlayerReady() {
      // Show custom play button when video is ready.
      this.$refs.ytPlay.classList.remove('button--hidden');
    },
    onPlayerStateChange(event) {
      // Removes the button when the video is playing.
      if (event.data === YT.PlayerState.PLAYING) {
        // If inside a video parallax, pause the playing video.
        this.bus.$emit('playing');
      }

      // Resume playing the video.
      if (
        event.data === YT.PlayerState.PAUSED ||
        event.data === YT.PlayerState.ENDED
      ) {
        this.bus.$emit('paused');
      }
    },
    randomHash(length) {
      // different ID
      let text = '';
      const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      Array(length)
        .fill('')
        .forEach(() => {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        });
      return text;
    },
  },
  created() {
    // Push player to global players array (v-model).
    this.value.push(() => {
      this.player = new YT.Player(this.playerId, {
        videoId: this.videoData.url.id,
        autoPlay: false,
        host: `${window.location.protocol}//www.youtube-nocookie.com`,
        playerVars: {
          origin: `${window.location.origin}`,
          host: `${window.location.protocol}//www.youtube-nocookie.com`,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    });
    this.$emit('input', this.value);
  },
  mounted() {
    // Load youtube iframe api (if not already there).
    let alreadyThere =
      document.querySelectorAll(`script[src="${this.scriptSrc}"]`).length > 0;
    if (!alreadyThere) {
      const tag = document.createElement('script');
      tag.src = this.scriptSrc;
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      alreadyThere = true;
    }
  },
};
</script>

<template>
  <div class="wrapper-nav-menu" :style="{ '--menu-wrapper-width': '84vw' }">
    <nav v-if="!onMobile" class="main-menu">
      <!-- menu on desktop only -->
      <ul class="main-menu__items">
        <li
          class="main-menu__item"
          v-for="item in mainMenuData"
          :key="item.url">
          <a
            :href="item.url"
            :target="item.target"
            @click="openDropdown($event, mainMenuData, item)"
            ref="navigation"
            class="main-menu__link"
            :class="{
              'main-menu__link--active': item.is_collapsed,
              'main-menu__link--current': item.in_active_trail,
              collapsed: item.is_expanded,
            }">
            <span class="main-menu__link-title">{{ item.title }}</span>
            <span class="main-menu__link-number" v-if="item.show_number">
              {{ openPositionsCount }}
            </span>
          </a>
          <transition name="fade">
            <ul class="main-sub-menu__items" v-if="item.is_expanded">
              <li>
                <a
                  :href="item.url"
                  :target="item.target"
                  class="main-menu__link main-menu__link--overview">
                  {{ `${item.title} ${t.mainMenu.overview}` }}
                </a>
              </li>
              <li
                class="main-sub-menu__item"
                v-for="sub_item in item.below"
                :key="sub_item.url">
                <a
                  :href="sub_item.url"
                  :target="sub_item.target"
                  class="main-sub-menu__link"
                  :class="{
                    'main-menu__link--current': sub_item.in_active_trail,
                  }">
                  {{ sub_item.title }}
                </a>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </nav>

    <!-- menu on mobile only -->
    <template v-if="onMobile">
      <!-- fixed button with menu icon -->
      <div
        class="main-menu__toggle"
        :class="{ 'main-menu__toggle-button--open': showMobileMenu }">
        <button class="main-menu__toggle-button" @click="toggleMobileMenu">
          <span class="main-menu__toggle-icons">
            <i class="fa-solid fa-bars"></i>
            <i class="fa-solid fa-xmark"></i>
          </span>
          <span class="main-menu__toggle-text">
            {{ t.mainMenu.menu }}
          </span>
        </button>
      </div>

      <div class="main-menu__wrapper" ref="mobileNavigation">
        <div
          class="main-menu--mobile"
          :class="{
            'main-menu--mobile-dropdown-open': dropdownOpen,
          }">
          <transition-group
            tag="ul"
            name="slide-fade"
            class="main-menu__items main-menu__items--mobile">
            <template v-if="showMobileMenu">
              <li
                class="main-menu__item main-menu__item--mobile"
                v-for="(item, index, idx) in mainMenuData"
                :key="`0${index}`"
                :style="{ transitionDelay: `${(idx + 1) * 0.1 - 0.1}s` }">
                <a
                  :href="item.url"
                  :target="item.target"
                  class="main-menu__link main-menu__link--mobile"
                  @click="openDropdown($event, mainMenuData, item)"
                  :class="{
                    'main-menu__link--active': item.is_collapsed,
                    collapsed: item.is_expanded,
                  }">
                  <span class="main-menu__link-title">{{ item.title }}</span>
                  <span class="main-menu__link-number" v-if="item.show_number">
                    {{ openPositionsCount }}
                  </span>
                </a>
                <!-- second level menu -->
                <ul
                  class="main-sub-menu__items main-sub-menu__items--mobile"
                  v-if="item.is_expanded">
                  <li>
                    <a
                      :href="item.url"
                      :target="item.target"
                      class="main-menu__link main-menu__link--overview">
                      {{ `${item.title} ${t.mainMenu.overview}` }}
                    </a>
                  </li>
                  <li
                    class="main-sub-menu__item"
                    v-for="sub_item in item.below"
                    :key="sub_item.url">
                    <a
                      :href="sub_item.url"
                      :target="sub_item.target"
                      class="main-sub-menu__link">
                      {{ sub_item.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </template>
          </transition-group>

          <!-- 'Kontakt' link -->
          <transition name="slide-fade">
            <a
              href="#"
              v-if="showMobileMenu"
              class="button button--secondary"
              :style="{
                transitionDelay: `${(countMenuItems + 1) * 0.1 - 0.1}s`,
              }"
              @click.prevent="scrollToContact">
              <span class="button__text">
                {{ t.mainMenu.toContact }}
              </span>
            </a>
          </transition>
        </div>
      </div>
    </template>

    <!-- overlay on mobile only, but needs to be in markup -->
    <!-- so we catch click for closing -->
    <transition name="fade">
      <div class="overlay" v-if="showMobileMenu" @click="close()"></div>
    </transition>
  </div>
</template>

<script>
// utils
import translationStrings from '../js/utils/translationStrings';
import isMobile from '../js/utils/isMobile';

export default {
  name: 'main-menu',
  props: {
    mainMenu: {
      type: Object,
      required: true,
    },
    openPositionsCount: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      showMobileMenu: false,
      mainMenuData: this.mainMenu,
      dropdownOpen: false,
      onMobile: isMobile(),
      t: translationStrings,
    };
  },
  computed: {
    countMenuItems() {
      return Number(Object.keys(this.mainMenu).length);
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.onMobile = isMobile();
    });

    window.addEventListener('keyup', ({ key }) => {
      if (this.showMobileMenu && (key === 'Escape' || key === 'Esc')) {
        this.close();
      }
    });
  },
  methods: {
    scrollToContact() {
      this.close();
      this.$nextTick(
        this.$root.scrollToItem('#block-footer-contact-block', true),
      );
    },
    openDropdown(event, data, currentItem) {
      // Reset dropdown open state.
      this.dropdownOpen = false;

      // Open dropdown submenu on click event.
      if (currentItem.is_expanded) {
        event.preventDefault();
        this.showMobileMenu = true;
      }

      // currentItem.is_collapsed === true, actually means
      // it is in fact NOT collapsed
      // (this is coming from Drupal, which thinks everything is expanded by default
      // ('always expanded' checkbox in Drupal block settings))
      const newState = !currentItem.is_collapsed;
      Object.keys(data).forEach((key) => {
        data[key].is_collapsed = false;
      });

      currentItem.is_collapsed = newState;

      // set new 'selected'/'clicked' sub menu item
      // so we can add class to whole navigation and move it
      this.dropdownOpen = newState;
    },
    toggleMobileMenu() {
      if (this.onMobile) {
        this.showMobileMenu ? this.close() : this.open();
      }
    },
    close() {
      this.showMobileMenu = false;
      // Close hamburger menu and remove overlay.
      if (this.onMobile) {
        this.$refs.mobileNavigation.classList.remove(
          'main-menu__wrapper--open',
        );
        document.body.style.overflowY = 'auto';
      } else {
        this.$refs.navigation.forEach((item) => {
          item.classList.remove('main-menu__link--active');
        });
      }

      if (this.dropdownOpen) {
        // CLose all open dropdowns.
        Object.keys(this.mainMenuData).forEach((key) => {
          this.mainMenuData[key].is_collapsed = false;
        });
      }
    },
    open() {
      // Hamburger click event to open the navigation.
      this.$refs.mobileNavigation.classList.add('main-menu__wrapper--open');
      document.body.style.overflowY = 'hidden';
      this.showMobileMenu = true;
    },
  },
};
</script>

<template>
  <div
    :class="{
      'accordion--open': isOpen,
      ...accClasses,
    }">
    <div class="accordion__head" @click="handleHeaderClick">
      <span class="count" v-if="modifier === 'paragraph'">00</span>
      {{ title }}
      <span class="plusminus" v-if="modifier === 'paragraph'"></span>
    </div>
    <div class="accordion__body" ref="body">
      <div class="accordion__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: {
    title: {
      type: String,
      request: true,
    },
    modifier: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    accClasses() {
      return {
        accordion: true,
        [`accordion--${this.modifier}`]: !!this.modifier,
      };
    },
  },
  methods: {
    handleHeaderClick() {
      // close other accordions before opening one
      if (this.modifier === 'paragraph') {
        // @todo
        /* eslint-disable-next-line */
        app.$children.forEach((child) => {
          if (
            child.$options.name === 'accordion' &&
            child.modifier === 'paragraph' &&
            child.isOpen
          ) {
            child.closeAccordion();
          }
        });
      }

      this.toggleAccordion();
    },
    toggleAccordion() {
      const bodyHeight = this.$refs.body.scrollHeight;
      this.isOpen = this.$el.classList.contains('accordion--open');

      if (!this.isOpen) {
        this.$refs.body.style.height = `${bodyHeight}px`;
      } else {
        this.$refs.body.style.height = '0';
      }

      this.isOpen = !this.isOpen;
    },
    closeAccordion() {
      this.$refs.body.style.height = '0';
      this.isOpen = false;
    },
  },
  mounted() {
    // if on paragraph
    if (this.modifier === 'paragraph') {
      // add numbers to the accordion items
      const accordions = [
        ...document.querySelectorAll('.paragraph-accordion'),
      ];
      accordions.forEach((accordion) => {
        [...accordion.querySelectorAll('.accordion__head .count')].forEach(
          (acc, idx) => {
            acc.textContent = `${idx + 1}`.padStart(2, '0');
          },
        );
      });
    }
  },
};
</script>
